import { useRequest } from 'ahooks';
import { Descriptions, Spin, Typography } from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { useEffect, useState } from 'react';
import { getAllGpoCategoriesApi } from '../../services/gpo/category.service';
import {
  queryAllBrandsByClassAndAgreements,
  queryGoodsSupplierAgreements,
} from '../../services/gpo/standard-goods.service';
import { getSingleProjectApi } from '../../services/project.service';
import { Action, querySingleActionApi } from '../../services/task.service';
import { getAllRJMCategoriesApi } from '../../services/rjmart/category.service';
import {
  findCarryFeeTemplate,
  getSupportBrands,
} from '../../services/rjmart/product-mamagement.service';
import { RJDeliveryTimes } from '../../services/rjmart/product-modal';

interface Item {
  name: string;
  val: string;
}
export function PlanParamView(props: {
  payload: string;
  projectId: string;
  actionId: string;
}) {
  const [items, setItems] = useState<Item[]>([]);
  const { loading, run: loadActionInfo } = useRequest(querySingleActionApi, {
    debounceWait: 300,
    loadingDelay: 300,
    manual: true,
    onSuccess(data) {
      const params = JSON.parse(props.payload || '{}');
      buildDescriptions(data, params);
    },
  });

  const buildDescriptions = async (
    action: Action,
    savedPayload: { [key: string]: any }
  ) => {
    const properties: { [key: string]: any } =
      action?.payloadSchema?.properties;
    const defKeys = Object.keys(properties);
    const tmpItems: Item[] = [];
    for (let index = 0; index < defKeys.length; index++) {
      const key = defKeys[index];
      const def = properties[key];
      const name = def.title || key;
      let val = savedPayload[key] === undefined ? '--' : savedPayload[key];
      if (savedPayload[key] !== undefined) {
        if (def.anyOf?.length) {
          const option = def.anyOf.find((x: any) => {
            return x.default === val;
          })?.title;
          val = option || val;
        } else if (def.contentMediaType === 'entity(Project)') {
          const project = await getSingleProjectApi(val);
          val = project.name || val;
        } else if (
          [
            'SupplierAgreementCategory(Category)',
            'entity(GpoCategory)',
            'entity(SupplierAgreementCategory)',
          ].includes(def.contentMediaType)
        ) {
          const allCategories = await getAllGpoCategoriesApi({
            projectId: props.projectId,
            ...explainDefaultParams(savedPayload, def.defaultParams),
          });
          const category = allCategories.find((x) => x.platformId === val);
          val = category?.platformName || val;
        } else if (
          [
            'entity(SupplierAgreementCategory[])',
            'entity(GpoCategory[])',
          ].includes(def.contentMediaType)
        ) {
          const allCategories = await getAllGpoCategoriesApi({
            projectId: props.projectId,
            ...explainDefaultParams(savedPayload, def.defaultParams),
          });
          const names = (val as string[]).map?.((x) => {
            return (
              allCategories.find((c) => c.platformId === x)?.platformName || x
            );
          });
          val = names?.join(', ') || val;
        } else if (def.contentMediaType === 'entity(SupplierAgreement)') {
          const agreements = await queryGoodsSupplierAgreements({
            projectId: props.projectId,
            ...explainDefaultParams(savedPayload, def.defaultParams),
          });
          const ag = agreements.find((x) => x.supplierAgreementGuid === val);
          val = ag?.agreementName || val;
        } else if (def.contentMediaType === 'entity(GpoBrand)') {
          const brands = await queryAllBrandsByClassAndAgreements({
            ...explainDefaultParams(savedPayload, def.defaultParams),
          });
          const ag = brands.find((x) => x.goodsBrandGuid === val);
          val = ag?.goodsBrandName || val;
        } else if (def.contentMediaType === 'entity(GpoBrand)') {
          const brands = await queryAllBrandsByClassAndAgreements({
            ...explainDefaultParams(savedPayload, def.defaultParams),
          });
          const ag = brands.find((x) => x.goodsBrandGuid === val);
          val = ag?.goodsBrandName || val;
        } else if (def.contentMediaType === 'entity(RJMCategory[])') {
          const arr = await getAllRJMCategoriesApi({
            ...explainDefaultParams(savedPayload, def.defaultParams),
          });
          const names = (val as string[]).map?.((x) => {
            return arr.find((c) => c.platformId === x)?.platformName || x;
          });
          val = names?.join(', ') || val;
        } else if (def.contentMediaType === 'entity(RJMCategory)') {
          const arr = await getAllRJMCategoriesApi({
            ...explainDefaultParams(savedPayload, def.defaultParams),
          });
          const ag = arr.find((x) => x.platformId === val);
          val = ag?.platformName || val;
        } else if (def.contentMediaType === 'entity(RJSupportBrand)') {
          const arr = await getSupportBrands({
            ...explainDefaultParams(savedPayload, def.defaultParams),
          });
          const ag = arr.list.find((x) => x.brandId === val);
          val = ag?.cname || val;
        } else if (def.contentMediaType === 'entity(RJDeliveryTimes)') {
          const ag = RJDeliveryTimes.find((x) => x.value === val);
          val = ag?.name || val;
        } else if (def.contentMediaType === 'entity(RJDeliverCostTemplate)') {
          if (!val) {
            val = '全国包邮';
          } else {
            const arr = await findCarryFeeTemplate({
              ...explainDefaultParams(savedPayload, def.defaultParams),
            });
            const ag = arr.find((x) => x.id === val);
            val = ag?.templateName || val;
          }
        } else if (def.contentMediaType === 'component(Password)') {
          val = '******';
        }
      }
      tmpItems.push({
        name,
        val,
      });
    }
    setItems(tmpItems);
  };

  const explainDefaultParams = (
    savedPayload: { [key: string]: any },
    defaultParams?: { [key: string]: any }
  ) => {
    const res: any = {};
    if (!defaultParams) {
      return res;
    }
    const keys = Object.keys(defaultParams);
    keys.forEach((key: string) => {
      const value = defaultParams[key];
      if (value.dependencies) {
        res[key] = savedPayload[value.dependencies[0]];
      } else if (value.global) {
        const globalKey: string = value.global;
        res[key] = Object.keys(props).includes(globalKey)
          ? (props as any)[globalKey]
          : '';
      } else {
        res[key] = value;
      }
    });
    return res;
  };

  useEffect(() => {
    loadActionInfo(props.actionId);
  }, []);
  return (
    <div
      style={{ width: 400, minHeight: 50, maxHeight: 300, overflow: 'auto' }}
    >
      {loading && <Spin spinning={true} />}
      {!loading && (
        <Descriptions labelStyle={{ width: 120 }} column={1} bordered>
          {items.map((x) => {
            return (
              <DescriptionsItem label={x.name} key={x.name + x.val}>
                <Typography.Text copyable={!!x.val && x.val !== '--'}>
                  {x.val}
                </Typography.Text>
              </DescriptionsItem>
            );
          })}
        </Descriptions>
      )}
    </div>
  );
}
